<template>
    <div class="app-bot">
        <div class="ab_top">
            <div style="float: left">
                <div class="abt_t">
                    <img src="../icon/b_logo.png">
                    <img src="../icon/b_phone.png" style="margin-left: 30px">
                </div>
                <div class="abt_l">
                    <span>新闻中心</span>
                    <span style="line-height: 43px">|</span>
                    <span>招贤纳士</span>
                    <span style="line-height: 43px">|</span>
                    <span>联系我们</span>
                    <span style="line-height: 43px">|</span>
                    <span>一舟集团</span>
                    <span style="line-height: 43px">|</span>
                    <span>一舟智网</span>
                </div>
            </div>

            <div style="float: right;margin-top: 45px;">
                <img src="../icon/b_code.jpg">
            </div>
        </div>
        <div class="ab_line"></div>
        <div class="ab_bot">
            Copyright 2003-2021 浙江一舟电子科技股份有限公司 浙ICP备15004471号
        </div>
    </div>
</template>

<script>
export default {
  name: "app-bottom",
  components: {

  },
  data() {
    return {};
  },
  mounted() {
  }
};
</script>

<style lang="less" scoped>
.app-bot{
    width: 100%;
    height: 260px;
    background: #242424;
    .ab_top{
        height: 200px;
        width: 1200px;
        margin: 0px auto;
        .abt_t{
            img{
                margin-top: 60px;
                margin-bottom: 10px;
            }

        }
        .abt_l{
            color: #CCCCCC;
            font-size: 16px;
            span{
                padding-right: 10px;
                cursor: pointer;
            }

        }
    }
    .ab_line{
        height: 1px;
        width: 100%;
        background: #666666;
    }
    .ab_bot{
        width: 1200px;
        margin: 0px auto;
        height: 59px;
        color: #666666;
        line-height: 59px;
        font-size: 14px;
    }
}
</style>
